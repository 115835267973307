// _colors.scss

// base colors

$base-color:			#008040;	// 19%

$base-dark: 			darken($base-color, 5); 	
$base-light:			lighten($base-color, 70);		
$base-background:		#ddffdd; 		// lighten($base-color, 50);

$body-background:		#f4f4f4;

// item colors

$logo-background: 					$base-dark;

$header-background:					$base-dark;

$footer-text: 						white;
$footer-background: 				$base-color;

$menu-background: 					$base-color;
$menu-background-parent:			$base-dark;
$menu-background-active:			$body-background;
$menu-text:							white;
$menu-text-active:					#404040;

$top-bar-background: 				$base-background;

$sidebar-toggle-background:			$base-color; 
$sidebar-toggle-background-logo: 	white;
$sidebar-toggle-text: 				white;

$searchbox-background: 				$base-light;
$searchbox-border: 					$base-dark;

$input-background:					#737373;
$input-text: 						gray;		// #808000