// _no_frames.scss

body
{
	background-color: $body-background;
}

#body
{
	.image
	{
		margin: 0.1rem auto;
		text-align: center
	}

	img
	{
		max-width: 100%;
		margin: 0.1rem;
		display: block;
		text-align: left
	}

	.padding
	{
		padding: 0rem 1rem;
	}

	hr
	{
	    border-bottom-color: $base-background;
	}

	table
	{
		margin: 0px;
		padding: 0px;
		border: none;

		td
		{
			margin: 0px;
			padding: 0px;
			border: none;
		}
	}
}

.icon-pdf {
	background-image: url('../images/pdf_logo_small.gif');
	background-position: left center;
	background-repeat: no-repeat;

	padding-left: 35px;
}

// --- sidebar header ----

#header
{
	background-color: $header-background;
}

#logo
{
	height: 135px;
	padding: 0.5rem;

	background-color: $logo-background;
}

// --- content area ---

#body-inner
{
	padding: 0rem 3rem;
}

#body-inner h1
{
	font-size: 2.5rem;
}

#body-inner h2
{
	font-size: 2rem;
}

#body-inner h3
{
	font-size: 1.5rem;
}

#body-inner #chapter
{
	padding-bottom: 1rem;
	max-width: 100%;
}

// --- sidebar footer ---

#footer
{
	padding-top: 2rem;

	color: $footer-text;
	background-color: $footer-background;
}

// --- menu ---

@mixin menu-link
{
	@include menu-link-a($menu-text, $menu-background);
}

@mixin menu-link-active
{
	@include menu-link-a($menu-text-active, $menu-background-active);
}

@mixin menu-sub-link
{
	@include menu-link-a($menu-text, $menu-background-parent);
}

@mixin menu-link-a($text, $back)
{
	a
	{
		background-color: $back;
		color: $text;
	}
}

@mixin menu-item-inactive // unselected sub item
{
	li
	{
		background-color: $menu-background-parent;

		@include menu-sub-link;		// unselected sub link
	}
}

#sidebar
{
	background-color: $menu-background;

	ul.topics
	{
		> li				// unselected top level item
		{
			background-color: $menu-background;

			@include menu-link;					// unselected top level link
		}

		> li.active	 		// selected top level group item
		{
			background-color: $menu-background-parent;

			@include menu-link-active;			// selected group link
			@include menu-item-inactive;		// unselected sub items
		}

		> li.parent	 		// expanded top level group
		{
			background-color: $menu-background-parent;

			@include menu-sub-link; 			// unselected group link
			@include menu-item-inactive;		// unselected sub items

			li.active				// selected sub item
			{
				background-color: $menu-background-active;

				@include menu-link-active;		// selected sub link
			}
		}
	}
}

// --- breadcrumbs ---

#top-bar
{
	background-color: $top-bar-background;
}

// --- mobile menu ---

#sidebar-toggle
{
	overflow: hidden;
	white-space: nowrap;
	width: 100%;

	padding: 0.3rem 1rem 0.1rem 0.5rem;
	margin-left: -1rem;

	background-color: $sidebar-toggle-background;
	color: $sidebar-toggle-text;

	a i
	{
		color: $sidebar-toggle-text;
	}
}

#overlay-title
{
	font-size: 1.5rem;
	padding-left: 1rem;
	text-overflow: ellipsis;
	white-space: nowrap;
}

#overlay-logo img
{
	margin-left: 1rem;
	margin-top: 3px;
	margin-bottom: 0px;

	height: 24px;

	background-color: $sidebar-toggle-background-logo;
}


// --- search ---

// --- search result page ---
.center
{
	.search-wrapper .search-submit
	{
//		display: none;
//		visibility: none;
	}
}

// --- search box ---

.search-submit {
	vertical-align: middle;

	img
	{
		-webkit-filter: opacity(50%);
		filter: opacity(50%);
	}
}

.search-item {
	margin-left: 1rem;
	margin-bottom: 1rem;
}

.searchbox
{
	border-width: 2px;
	border-style: solid;
	border-radius: 4px;

	background-color: $searchbox-background;
	border-color: $searchbox-border;

	.search-submit
	{
		border: none;

		background-color: $searchbox-background;
	}

	input
	{
		color: $input-background;
	}

	input::-webkit-input-placeholder
	{
		color: $input-text;
	}
	input::-moz-placeholder
	{
		color: $input-text;
	}
	input:-moz-placeholder
	{
		color: $input-text;
	}
	input:-ms-input-placeholder
	{
		color: $input-text;
	}
}

